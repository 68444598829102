import React, { useState, useEffect, useRef } from "react";
import {  Button, Box, IconButton, Popover, Typography } from "@mui/material";
import { DropzoneArea } from "mui-file-dropzone";
import './css/FileUpload.css';

import HelpIcon from '@mui/icons-material/Help';

const singleSizeLimit = parseInt(process.env.REACT_APP_SINGLE_SIZE_LIMIT == undefined ? "0" : process.env.REACT_APP_SINGLE_SIZE_LIMIT)
const totalSizeLimit = parseInt(process.env.REACT_APP_TOTAL_SIZE_LIMIT == undefined ? "0" : process.env.REACT_APP_TOTAL_SIZE_LIMIT)
const acceptedFileTypes = process.env.REACT_APP_ACCEPTED_FILES
const acceptedFileTypesSplit = process.env.REACT_APP_ACCEPTED_FILES?.split(",")

const parentDivStyleNoDrag = {
  width: '100%',
  height:'100%',
  position: 'absolute',
  zIndex: -1,
  top: 0,
  bottom: 0,
  '.dropzoneDiv':{
    width: '100%',
    height:'100%',
    border: '0px',
    visibility:'hidden',
  },
  svg:{
    visibility:'hidden'
  },
  '.help':{
    color:'grey',
    height:'fit-content',
    alignSelf:'center',
    svg:{      
      visibility:'visible',
    }
  },
  '.dropzoneText' :{
    fontSize: '21px',
    color: 'gray',
    borderStyle: 'dashed',
    borderWidth: '2px',
    padding: '5px',
    borderRadius: '10px',
    zIndex:5,
    textTransform:'none',
  }
}

const parentDivStyleWithDrag = {
  width: '100%',
  height:'100%',
  position: 'absolute',
  zIndex: 10,
  top: 0,
  bottom: 0,
  '.dropzoneDiv':{
    width: '100%',
    height:'100%',
    border: '0px',
    visibility:'visible',
    background:'none',
    zIndex: 10,
  },
  svg:{
    visibility:'hidden'
  },
  '.help':{
    fontSize:'25px',
    color:'grey',
    height:'fit-content',
    alignSelf:'center',
    svg:{      
      visibility:'visible',
    }
  },
  '.dropzoneText' :{
    fontSize: '21px',
    color: 'gray',
    borderStyle: 'dashed',
    borderWidth: '2px',
    padding: '5px',
    borderRadius: '10px',
    zIndex: 5,
    textTransform:'none',
  }
}

/**
 * @param props 
 */
const FileUpload = (props: any) => {
  const {containerClient, blobData, dragHandlerReceiver} = props.data
  const [dropzoneKey, setKey] = useState(0);
  const [currentStyle, setStyle] = useState(parentDivStyleNoDrag);
  const [className, setClass] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [infoModal, setInfoModal] = React.useState<HTMLElement | null>(null);
  const openInfo = Boolean(infoModal);

  let vfile: {"rejected":boolean, "reason":string, "file":File}
  let valFiles: typeof vfile[] = [] 


  function setStartDrag(){
    setStyle(parentDivStyleWithDrag);
    setClass("uploadAnimation");
  }

  function setEndDrag(){
    setStyle(parentDivStyleNoDrag);
    setClass("");
  }



  const uploadFile = async () => {
    props.isUploadingSender(true)
    let upldSucccess: typeof valFiles = []
    let upldFail: typeof valFiles = []
    for (let f of valFiles){
  
      if(!f.rejected){
        const uploadOptions = {
          blobHTTPHeaders:{
            'blobContentType':f.file.type
          }
        }
        const newBlob = await containerClient.getBlockBlobClient(`${f.file.name}`)
        await newBlob.uploadData(f.file, uploadOptions)
          .then((res: any) => {
            if(res._response.status = 201){
              console.log(`File ${f.file.name} uploaded`)
              upldSucccess.push(f)
            }else{
              console.log(`File ${f.file.name} failed to upload`)
              upldFail.push(f)
            }
          })
      }else{
        console.log(f.reason)
      }
    } 
    ///// Dropzone maintains an internal state, so it's not possible to empty the dropped files
    ///// I achieve that by changing Dropzone's div ID so it re-renders
    if (upldSucccess.length > 0){
      props.snackbarHandler("success", upldSucccess)
    }
    if (upldFail.length > 0){
      props.snackbarHandler("error", upldFail)      
    }

    setFiles([])
    valFiles = []
    setKey(dropzoneKey + 1)
    props.updateBlobData()
  }

  const validateUpload = () => {
    let containerSize = 0
    let newFilesSize = 0
    let uploadedFiles = []
    let rejectedFiles: typeof valFiles = []

    for (let b of blobData){
      containerSize += b.properties.contentLength
      uploadedFiles.push(b.name.split('/').slice(-1)[0])
    }
    for (let f of files){
      newFilesSize += f.size
      let ff: typeof vfile
      let fileType = f.name.split(".").splice(-1)[0].toLowerCase() //get file suffix from each uploaded file
      
      if (f.size > singleSizeLimit){
        ff = {"file":f, "rejected":true, "reason":"size"}
        rejectedFiles.push(ff)
      }else if (uploadedFiles.includes(f.name)){
        ff = {"file":f, "rejected":true, "reason":"duplicate"}
        rejectedFiles.push(ff)
      }else if(!acceptedFileTypesSplit?.includes(fileType)){
        ff = {"file":f, "rejected":true, "reason":"type"}
        rejectedFiles.push(ff)
      }else{
        ff = {"file":f, "rejected":false, "reason":""}  
        valFiles.push(ff)
      }
    }
    if(rejectedFiles.length > 0){
      props.snackbarHandler("reject", rejectedFiles)
    }    
  
    if(containerSize + newFilesSize > totalSizeLimit){
      return false
    }
    return true
  }

  const openFileDialog = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };


  const manualUpload = (e: any) => {
    if (e.target.files.length > 0){
      const newFIles = e.target.files
      setFiles(newFIles)
    }
  }

  const handleInfoOpen = (event: React.MouseEvent<HTMLElement>) => {
    setInfoModal(event.currentTarget);
  };

  const handleInfoClose = () => {
    setInfoModal(null);
  };

  useEffect(() => { 
    if (files.length > 0){ 
      if (validateUpload()){
        uploadFile()
      }else{
        props.snackbarHandler("full")
        setFiles([])
        console.log("Container Full")
      }
    } 
  }, [files]) 

  useEffect(() => {
    if(dragHandlerReceiver){
      setStartDrag()
      props.dragHandlerSender(false)
    }
  }, [dragHandlerReceiver])

  return (
    <Box className={className} key={dropzoneKey} sx={currentStyle} 
    onDragEnd={() => setEndDrag()} 
    onDrop={() => setEndDrag()} 
    onDragEnter={() => setStartDrag()}>
      <DropzoneArea
        dropzoneClass="dropzoneDiv"
        dropzoneText=""
        fileObjects={files}
        showPreviews={false}
        showPreviewsInDropzone={false}
        onChange={(newFiles) => setFiles(newFiles)}
        maxFileSize={502428800}
        showAlerts={false}
        dropzoneProps={{
          noKeyboard:true,
          noClick:true,
          onDragLeave:() => setEndDrag()
        }}
      />
      <Box sx={{display:'flex', justifyContent:'center', position:'absolute', bottom:0, marginBottom:'2vh', width:'100%'}}>
          <Button className="dropzoneText" onClick={openFileDialog}>
            Click or Drop Files anywhere to Upload 
          </Button>
          <input onChange={e => manualUpload(e)} multiple type='file' id='file' ref={inputFile} style={{display: 'none'}}/>
        <IconButton disableRipple onMouseOver={handleInfoOpen} onMouseLeave={handleInfoClose} className="help">          
          <HelpIcon ></HelpIcon>
        </IconButton>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={openInfo}
          anchorEl={infoModal}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={handleInfoClose}
          disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>
          1) Το συνολικό επιτρεπόμενο μέγεθος για το quote είναι {totalSizeLimit / 1024 / 1024}MB <br/>
          2) Το επιτρεπόμενο μέγεθος για κάθε αρχείο ειναι {singleSizeLimit / 1024 / 1024}MB <br/>
          3) Οι επιτρεπόμενοι τύποι αρχείου είναι {acceptedFileTypes} <br/>
        </Typography>
      </Popover>
      </Box>
    </Box>
  )
}

export default FileUpload;