import React, { useState, useEffect, useMemo } from "react";
import { 
  Button,
  IconButton,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  DialogTitle,
  Dialog,
  Checkbox,
  Tooltip
 } from "@mui/material";

import { format } from "date-fns";

import CloseIcon from '@mui/icons-material/Close';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

/**
 * @param props 
 */
const tableStyle = {
  width: '60vw',
  margin: 'auto',
  paddingBottom: '40px',
}

const tableParentStyle = {
  paddingBottom: '60px',
  boxShadow: '0px 0px 0px 0px',
  height:'60vh'
}

const deleteSelectedStyle = {
  position:'absolute',
  marginTop: '74vh',
  transform: 'translate(-50%, -50%)',
  height:'80px',
  width: '80px',
  transition: 'opacity 0.4s',
  opacity: 1,
  top: 0,
}
const deleteSelectedStyleHidden = {
  pointerEvents: 'none',
  position:'absolute',
  marginTop: '74vh',
  transform: 'translate(-50%, -50%)',
  height:'80px',
  width: '80px',
  transition: 'opacity 0.4s',
  opacity: 0,
  top: 0,
}

const deletedIconStyle = {
  visibility:'visible !important',
  height:'60px',
  width: '60px',
}

const UploadedView = (props: any) => {
  const {containerName, containerClient, blobData} = props.data;
  const {sx} = props;
  const [openOption, setOpenOption] = useState(false);
  const [deleteOption, setDeleteOption] = useState();
  const [fileName, setFileName] = useState("");
  const [isDrag, setDrag] = useState(false);
  const [selection, setSelection] =  useState(blobData); 
  const [isAnySelected, setAnySelected] = useState(false);
  
  const accountName = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME
  const fileLink = `https://${accountName}.blob.core.windows.net/` + containerName + "/"

  const deleteBlob = async () => {
    let dltSucccess: {}[] = []
    let dltFail: {}[] = []
    props.isUploadingSender(true)
    setOpenOption(false);
    const options = {
      deleteSnapshots: 'include'
    }
    
    for (let s of selection){
      if (s.selected){
        let blobName = s.name
        const blob = await containerClient.getBlockBlobClient(blobName)
        
        await blob.deleteIfExists(options)
        .then((res: any) =>{
          if(res.succeeded){                    
            console.log(`Deleted blob ${blobName}`)
            dltSucccess.push({"name":s.name})
          }else{
            console.log(`Υπήρξε πρόβλημα με την διαγραφή. ErrorCode: ${res.errorCode}`)
            dltFail.push({"name":s.name})
          }
        })
      }
    }

    if (dltSucccess.length > 0){
      props.snackbarHandler("success", dltSucccess)
    }
    if (dltFail.length > 0){
      props.snackbarHandler("error", dltFail)      
    }

    deSelectAll()
    props.updateBlobData()
  }  
  
  const handleClickOpen = (row?: any) => {
    if(row){
      selection[row.idx].selected = !selection[row.idx].selected
      setSelection([...selection])
    }
    setOpenOption(true);
  };

  const handleClose = () => {
    setOpenOption(false);
    deSelectAll()
  };

  const checkSelected = () => {
    let selected = false
    for (let s of selection){
      if (s.selected){
        selected = true
      }
    }

    if(selected){
      setAnySelected(true)
    }else{
      setAnySelected(false)
    }
  }

  const handleSelect = (row: any) => {
    selection[row.idx].selected = !selection[row.idx].selected
    setSelection([...selection])
    checkSelected()
  }

  const selectAll = (e: any) => {
    for (let s of selection){
      s.selected = e.target.checked
    }
    setSelection([...selection])
    checkSelected()
  }
  const deSelectAll = () => {
    for (let s of selection){
      s.selected = false
    }
    setSelection([...selection])
    checkSelected()
  }
  

  useEffect(() => {
    if(isDrag){
      props.dragHandlerSender()
      setDrag(false)
    }
  }, [isDrag])

  useEffect(() => {
    setSelection([...blobData])
    props.isUploadingSender(false)
  }, [blobData])

  return(
    <Box sx={sx} onDragEnter={() => setDrag(true)}>
      <TableContainer sx={tableParentStyle} component={Paper}>
        <Table stickyHeader size="small" sx={tableStyle} aria-label="File Table">
          <TableHead>
            <TableRow>
                <TableCell>
                  <Checkbox onChange={(e) => selectAll(e)} size="small"  />
                </TableCell>
              <TableCell >Name</TableCell>
              <TableCell align="right">Size</TableCell>
              <TableCell align="right">Uploaded On</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blobData.map((row: any)=> (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                selected={row.idx >= selection.length? false : selection[row.idx].selected}
              >
                <TableCell>
                  <Checkbox checked={row.idx >= selection.length? false : selection[row.idx].selected} onChange={() => handleSelect(row)} size="small" /> 
                </TableCell>
                <TableCell  component="th" scope="row">
                  <a href={fileLink + row.name} target="_blank">{row.name.split('/').slice(-1)}</a>
                </TableCell>
                <TableCell align="right">{checkSize(row.properties.contentLength)}</TableCell>
                <TableCell align="right">{format(row.properties.createdOn, "dd/MM/yyyy H:mm")}</TableCell>
                <TableCell align="right">
                  <Tooltip title={"Διαγραφή"}>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      disabled={isAnySelected}
                      onClick={() => {
                          //deleteBlob(row.name)
                          handleClickOpen(row)
                      }}
                    >
                      <CloseIcon></CloseIcon>
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Tooltip title={"Διαγραφή Επιλογής"}>
        <IconButton onClick={() => handleClickOpen()} size={'large'}  sx={isAnySelected ? deleteSelectedStyle : deleteSelectedStyleHidden }>
          <DeleteRoundedIcon sx={deletedIconStyle}/>
        </IconButton>
      </Tooltip>
      <Dialog
        open={openOption}
        onClose={handleClose}
      >
        <DialogTitle sx={{margin:'auto'}}>Διαγραφή Αρχείων</DialogTitle>
        <Box sx={{padding:'20px', paddingBottom:'30px', paddingTop:'5px'}}>
          Θέλετε να προχωρήσετε σε διαγραφή των παρακάτω αρχείων; 
          <br/>
          <br/>
          {
            selection.map((s: any) => {
              if (s.selected){
                return (
                  <div key={s.idx}>
                  {s.name.split('/').slice(-1)}
                  <br/>
                  </div>
                )
              }
            })
          }
          <br/>
          Προσοχή: Η διαγραφή δεν αναιρείται.
        </Box>
        <Box sx={{display:'flex', paddingBottom:'15px'}}>
          <Button variant="outlined" sx={{marginLeft:'25px'}} onClick={handleClose}>
            Ακυρωση
          </Button>
          <Button variant="contained" sx={{marginLeft:'auto', marginRight:'25px'}} onClick={() => deleteBlob()}>
            Ναι
          </Button>
        </Box>
      </Dialog>
  </Box>
  )
}

export default UploadedView;

function checkSize(size: number){

  let newSize = size / 1024
  if (newSize > 1024){
    newSize = newSize / 1024
    return (`${newSize.toFixed(2)} MB`)
  }else{
    return (`${newSize.toFixed(2)} KB`)
  }
}