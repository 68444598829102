import React from 'react';

import {Box, CircularProgress } from '@mui/material';

const style = {
  marginTop:'20px', 
  height:'30vh', 
  width:'30vw',  
  display:'grid',
  pointerEvents:'none',
}

const LoadingCircle = (props: any) => {
  const {sx} = props

    return (
        <Box sx={{...style, ...sx}}>
          <CircularProgress sx={{placeSelf: 'center'}} size={70}/>
        </Box>
    );
  };
  
export default LoadingCircle;