import React, { useState, useEffect } from 'react';
import './App.css';
import FileUpload from './components/FileUpload';
import UploadedView from './components/UploadedView';
import LoadingCircle from './components/LoadingCircle';
import {Box, IconButton, Tooltip, Grow } from '@mui/material';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import CloseIcon from '@mui/icons-material/Close';

import { PageLayout } from './components/PageLayout';
import { loginRequest } from './authConfig';
import { callMsGraph } from './graph';

import { AuthenticatedTemplate, useAccount, useIsAuthenticated, useMsal, useMsalAuthentication  } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import { } from "@azure/storage-blob";
import { BlobServiceClient, ContainerClient} from "@azure/storage-blob";
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack'



const lowOpacity = { 
  height: 'auto', 
  lineHeight: '28px', 
  opacity:0.7,
  transition: 'opacity 0.4s',  
  whiteSpace: 'pre-line'
}

const highOpacity = { 
  height: 'auto',
  lineHeight: '28px', 
  opacity:1,
  transition: 'opacity 0.4s',  
  whiteSpace: 'pre-line' 
}

export function App() {
  const [snackStyle, setSnackStyle] = useState<{} | undefined>(lowOpacity);
  const [username, setUsername] = useState(null);

  return (
    <PageLayout
      user={username}
    >
      <center>
        <br/>
        <SnackbarProvider 
          TransitionComponent={Grow}
          style={snackStyle}
          onMouseOver={() => setSnackStyle(highOpacity)}
          onMouseOut={() => setSnackStyle(lowOpacity)}
        >
          <MainContent 
            setUser={(usr: any) => setUsername(usr)}
           />
        </SnackbarProvider> 
      </center>
    </PageLayout>
  );
}

const singleSizeLimit = parseInt(process.env.REACT_APP_SINGLE_SIZE_LIMIT == undefined ? "0" : process.env.REACT_APP_SINGLE_SIZE_LIMIT)
const totalSizeLimit = parseInt(process.env.REACT_APP_TOTAL_SIZE_LIMIT == undefined ? "0" : process.env.REACT_APP_TOTAL_SIZE_LIMIT)

const fadeIn = {
  opacity: 1,
  transition: 'opacity 0.2s',
  position:'absolute',
  left: 0,
  right: 0,
  marginLeft:'auto',
  marginRight:'auto',
}

const fadeOut = {
  opacity: 0,
  transition: 'opacity 0.2s',
  position:'absolute',
  left: 0,
  right: 0,
  marginLeft:'auto',
  marginRight:'auto',
}

// http://localhost:3000/?id=test&vat=2210598&qid=Q-123456
const MainContent = (props: any) => {
  // TODO 
  // create env profile for storage name uat / prod 
  // sticky end of website, maybe scroll for files (will need testing for size)
  // automatic session clear?


  const [accountData, setAccData] = useState<any>(false); 
  const [admin, setAdmin] = useState<boolean>(false); 
  const [blobData, setBlobData] = useState<{}>(); 
  const [containerClient, setClient] = useState<ContainerClient>();
  const [urlQueries, setUrlQueries] = useState<{oppId:string | null, qid:string | null , vat:string | null}>();
  const [isDrag, setDrag] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [containerName, setContainerName] = useState<any>();
  const [accToken, setAccToken] = useState<string>();
  const [userError, setUserError] = useState<string>();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});  
  const isAuthenticated = useIsAuthenticated();
  const { login, result, error } = useMsalAuthentication(InteractionType.Redirect, loginRequest);

  let vfile: {"rejected":boolean, "reason":string, "file":File}
  let valFiles: typeof vfile[] = [] 

  const accountName = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME
  const regex = /[\\\/@!$%^&*()_+|~=`{}[\]:";'<>?,. ]/mg;
  const subst = ""

  const checkContainer = async () => {
    let blobServiceClient: BlobServiceClient

    const storageSas = process.env.REACT_APP_GETSAS_ENDPOINT as string
    const sas_req = new Request(storageSas);
    fetch(sas_req)
      .then((res) => {
        if (res.ok){
          return res.json()
        }else{
          throw new Error(JSON.stringify({"status":res.status, "msg":res.statusText}));
        }        
      })
      .then((data) => {
        const sas = data.sas
        blobServiceClient = new BlobServiceClient(
          `https://${accountName}.blob.core.windows.net?${sas}`
        );

      })

    // removing symbols from e-mail for container name
    const userEmail = accountData.mail;
    //const userEmail = "akarras@heron.gr"
    const agCodeEndpoint = process.env.REACT_APP_GETHIERARCHY_ENDPOINT + `?&sp_email=${userEmail}`
    const request_q = new Request(agCodeEndpoint);
    fetch(request_q)
      .then((res) => {
        if (res.ok){
          if (res.status == 204){
            setUserError(`Δεν βρέθηκε ο χρήστης ${userEmail}.`)
            throw new SyntaxError(JSON.stringify({"status":res.status, "msg":res.statusText}));
          }
          return res.json()
        }else{
          setUserError(`Κάτι πήγε στραβά. Παρακαλώ προσπαθήστε ξανά αργότερα.`)
          throw new Error(JSON.stringify({"status":res.status, "msg":res.statusText}));
        }
      })
      .then(async (data) =>{
        const hierarchy = data.user_list.map((u: string) => u.toUpperCase());
        const folder = urlQueries?.oppId ? urlQueries?.oppId.toLowerCase() : ""
        
        setContainerName(folder)
        const container = blobServiceClient.getContainerClient(folder)
        setClient(container)

        let properties = await container.getProperties()
        let lastOwner = properties['metadata']?.lastowner.toUpperCase()

        if (hierarchy.includes(lastOwner) || admin){
          return await getAllBlobData(container)
        }else{
          setUserError(`Δεν έχετε πρόσβαση να δείτε αυτή την αίτηση.`)
          console.log(`Access Denied - Owner: ${lastOwner}`);
        }
        
      })
      .catch((e) => {
        console.log(e)
      })
  }
  
  const getAllBlobData = async (container?: ContainerClient) => {
    let allBlobs = []
    let idx = 0
    
    if(container && urlQueries){
      for await(const blob of container.listBlobsByHierarchy('/')){
        if(blob.kind === 'blob'){
          let x = {"properties": blob.properties, "name":blob.name, "selected":false, "idx":idx}
          idx++
          allBlobs.push(x)
        }
      }
      setBlobData([...allBlobs])
      setUploading(false)
    }
  }

  const getBlobData = async () => {
    if (accToken){
      callMsGraph(accToken).then((response) => {
        if(document.location.pathname !== "/" + urlQueries?.qid){
          window.history.pushState({}, document.title, "/" + urlQueries?.qid);
        }
        for (let res of response["responses"]){
          if (res["id"] == "1"){ // id == 1 -> graph /me
            setAccData(res["body"])
          }else if (res["id"] == "2"){ // id == 2 ->  graph /me/memberOf with filter admin
            if (res["status"] == 200){
              setAdmin(true)
            }
          }
        }
      });
    }else{
      console.log("CLEAR SESSION STORAGE?")
    }
  }
  const snackbarDelete = (status: string, files: [{"name": string}]) => {
    let dltMsg = {
      "success" : {"variant":"success", "msg": `Τα παρακάτω αρχεία διαγράφηκαν επιτυχώς: \n ${files.map((f)=>{return f.name.split("/").splice(-1)[0]}).join("\n")}` },
      "error" : {"variant":"error", "msg": `Υπήρξε ένα πρόβλημα κατα την διαγραφή των παρακάτω αρχείων: \n ${files.map((f)=>{return f.name.split("/").splice(-1)[0]}).join("\n")})}\n\n Παρακαλώ προσπαθήστε ξανά.` } 
  }

    enqueueSnackbar(dltMsg[status as keyof typeof dltMsg].msg, {
      variant: dltMsg[status as keyof typeof dltMsg].variant as VariantType,
      action: (key) => (
        <IconButton
          size="small"
          aria-label="delete"
          onClick={() => {
              closeSnackbar(key)
          }}
        >
          <CloseIcon></CloseIcon>
        </IconButton>
      )
    })

  }
  const snackbarHandler = (status: string, files?: typeof valFiles) => {
    let rsnMsg = {
      "duplicate": "Υπάρχει ήδη αρχείο με το ίδιο όνομα",
      "size" : `Υπερβαίνει το μέγιστο όριο μεγέθους (${singleSizeLimit / 1024 / 1024}MB)`,
      "type" : "Λάθος τύπος αρχείου"
    }
    let types = {
      "success" : {"variant":"success", "msg":`Τα παρακάτω αρχεία έγιναν upload επιτυχώς: \n ${files?.map((f)=>{return f.file.name}).join("\n")}`},
      "error" : {"variant":"error", "msg":`Υπήρξε ένα πρόβλημα κατα το upload των παρακάτω αρχείων: \n ${files?.map((f)=>{return f.file.name}).join("\n")})}
    \n\n Παρακαλώ προσπαθήστε ξανά.`},
      "full" : {"variant":"error", "msg":`Τα αρχεία απέτυχαν να γίνουν upload διότι ο φάκελος σας υπερβαίνει το μέγιστο όριο δεδομένων (${totalSizeLimit / 1024 / 1024}MB). Παρακαλώ ελέγξτε τα αρχεία και ξανα προσπαθήστε.`},
      "reject" : {"variant":"error", "msg":`Τα παρακάτω αρχεία δεν θα γίνουν upload: \n ${files?.map((f)=>{return `${f.file.name} - ${rsnMsg[f.reason as keyof typeof rsnMsg]}`}).join("\n")}`},
    }

    enqueueSnackbar(types[status as keyof typeof types].msg, {
      variant: types[status as keyof typeof types].variant as VariantType,
      action: (key) => (
        <IconButton
          size="small"
          aria-label="delete"
          onClick={() => {
              closeSnackbar(key)
          }}
        >
          <CloseIcon></CloseIcon>
        </IconButton>
      )
    })
  }

  useEffect(()=>{
    async function getTokenSilently() {
      const tokenRequest = {
        scopes: ["User.Read","offline_access"],
        // loginHint: accounts[0].username,
        account: account ? account : undefined,
      };

      const res = await instance.acquireTokenSilent(tokenRequest);       
      setAccToken(res.accessToken)
    }

    if(result){
      setAccToken(result.accessToken)
    }else if (isAuthenticated && inProgress === 'none'){
      getTokenSilently();
    }

  }, [account && inProgress === 'none'])

  useEffect(() => {
    if(accountData){
      props.setUser(accountData.mail)
      checkContainer()
    }
  }, [accountData, admin])

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    const oppId = searchParams.get('id');
    const qid = searchParams.get('qid');
    const vat = searchParams.get('vat');

    if(accToken){
      if(qid){
        window.sessionStorage.setItem(qid, document.location.search)
  
        setUrlQueries({
          oppId: oppId,
          qid: qid,
          vat: vat
        })
      }else{
        const url = window.sessionStorage.getItem(document.location.pathname.replace("/", ""));
  
        if (url){
          const searchParams = new URLSearchParams(url)
          setUrlQueries({
            oppId: searchParams.get('id'),
            qid: searchParams.get('qid'),
            vat: searchParams.get('vat')
          })
        }
      }
    }    
    
  }, [accToken]) 

  useEffect(()=> {
    getBlobData()

  }, [urlQueries])
  
  return (
    <AuthenticatedTemplate>
      <Box >
        <h5 style={{textDecoration:'underline 1px', marginBottom:'5px'}} className="card-title">ΑΦΜ {urlQueries?.vat} - Dealhub ID {urlQueries?.qid}
        <Tooltip title={"Ανανέωση"}>
          <IconButton sx={{marginLeft:'10px', position:'absolute', zIndex:'9', height:'50px', width:'50px', transform:'translate(0, -24%)'}}
            onClick={() => {setUploading(true); getAllBlobData(containerClient)}}
            disabled={containerClient && !userError ? false : true}
          >        
            <RefreshRoundedIcon sx={{height:'35px', width:'35px', color:'#434242'}}/>
          </IconButton>
        </Tooltip>
        </h5>
      </Box>
      {blobData && accountData && containerClient && urlQueries && containerName ? (
        <Box >
          <Box sx={{position:'relative'}}>
            <UploadedView sx={isUploading ? fadeOut : fadeIn} data={{
              containerClient: containerClient,
              blobData: blobData,
              containerName: containerName
            }}
              updateBlobData = {() => getAllBlobData(containerClient)}
              dragHandlerSender = {() => {setDrag(true)}}
              isUploadingSender = {(state: boolean) => (setUploading(state))}
              snackbarHandler = {(status: string, files:  [{"name": string}]) => snackbarDelete(status, files)}
            />
            <LoadingCircle sx={isUploading ? fadeIn : fadeOut} />
          </Box>
          <FileUpload  data={{
            containerClient: containerClient, 
            blobData: blobData,
            containerName: containerName,
            dragHandlerReceiver: isDrag,
          }}
            updateBlobData = {() => getAllBlobData(containerClient)}
            dragHandlerSender = {() => {setDrag(false)}}
            isUploadingSender = {(state: boolean) => (setUploading(state))}
            snackbarHandler = {(status: string, files?: typeof valFiles) => snackbarHandler(status, files)}
            ></FileUpload>
        </Box>
      ) : 
        userError ? (
            <Box className='errLabel'>
              <label>{userError}</label>
            </Box>
          ) : (
            <LoadingCircle />
          )
      }
      
    </AuthenticatedTemplate>
  );
};

