/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function callMsGraph(accessToken: any) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    const graphMeEndpoint = "https://graph.microsoft.com/v1.0/$batch"
    const adminId = "bf12d589-6dd3-41a2-b0a6-f277466b4b9b" // group id of portal list admin

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json")

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            "requests": [
                {
                    "id": "1",
                    "method": "GET",
                    "url": "/me"
                },
                {
                    "id": "2",
                    "method": "GET",
                    "url": `/me/memberOf?$filter=id eq '${adminId}'`
                }
            ]
        })
    };

    return fetch(graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}